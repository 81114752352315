// Taken from a list of NSA codenames
const NAMES: string[] = [
  'AARON',
  'ABBOT',
  'ABHORSON',
  'ABRAHAM',
  'ACCORDIAN',
  'ACHILLES',
  'ACIDWASH',
  'ACORN',
  'ACROBAT',
  'ADAM',
  'ADRIAN',
  'ADRIANA',
  'AEDILE',
  'AEGEON',
  'AEMILIA',
  'AEMILIUS',
  'AENEAS',
  'AETHER',
  'AGAMEMNON',
  'AGILEVIEW',
  'AGILITY',
  'AGRIPPA',
  'AIRGAP',
  'AIRSTEED',
  'AIRWOLF',
  'AJAX',
  'ALAMITO',
  'ALBATROSS',
  'ALCIBIADES',
  'ALEXANDER',
  'ALEXAS',
  'ALICE',
  'ALONSO',
  'ALPHA',
  'ALTEREGO',
  'AMBASSADOR',
  'AMBERJACK',
  'AMBLE',
  'AMIENS',
  'ANARCHIST',
  'ANCHORY',
  'ANDROMACHE',
  'ANGEL',
  'ANGELO',
  'ANGLER',
  'ANGUS',
  'ANOTHER',
  'ANTIGONUS',
  'ANTIOCHUS',
  'ANTONIO',
  'ANTONY',
  'APALATCHEE',
  'APEMANTUS',
  'APERIODIC',
  'APEX',
  'APOLLO',
  'APOTHECARY',
  'APPLESAUCE',
  'APSTARS',
  'AQUACADE',
  'AQUADOR',
  'ARCA',
  'ARCHIDAMUS',
  'ARGON',
  'ARGYLEALIEN',
  'ARIEL',
  'ARKSTREAM',
  'ARMOR',
  'ARROW',
  'ARTEMIDORUS',
  'ARTEMIS',
  'ARTHUR',
  'ARTIFICE',
  'ARVIRAGUS',
  'ASPHALT',
  'ASSOCIATION',
  'ATALANTA',
  'ATLAS',
  'ATTENDANT',
  'ATTENDANTS',
  'AUDREY',
  'AUNTIE',
  'AURORA',
  'AUTHOR',
  'AUTOLYCUS',
  'AUTOSOURCE',
  'AVALANGE',
  'AZUREPHOENIX',
  'BABBLEQUEST',
  'BACCHUS',
  'BADASS',
  'BADGER',
  'BADGIRL',
  'BAGOT',
  'BAGPIPE',
  'BALBOA',
  'BALLOONKNOT',
  'BALTHASAR',
  'BALTHAZAR',
  'BAMBOOSPRING',
  'BANCROFT',
  'BANDIT',
  'BANDITTI',
  'BANISTER',
  'BANQUO',
  'BANYAN',
  'BARDOLPH',
  'BARNARDINE',
  'BARON',
  'BARONESS',
  'BASECOAT',
  'BASILHAYDEN',
  'BASSANIO',
  'BASSET',
  'BASSIANUS',
  'BASTE',
  'BAT',
  'BATCAVE',
  'BATES',
  'BATON',
  'BAWD',
  'BAYBRIDGE',
  'BEACHMAN',
  'BEADLE',
  'BEAMER',
  'BEAR',
  'BEATRICE',
  'BEE',
  'BEEHIVE',
  'BEHEMOTH',
  'BELARIUS',
  'BELLTOPPER',
  'BELLVIEW',
  'BENEDICK',
  'BENVOLIO',
  'BERNARDO',
  'BERTRAM',
  'BIANCA',
  'BIGBIRD',
  'BIGDIPPER',
  'BIGOT',
  'BINOCULAR',
  'BIONDELLO',
  'BIRCHWOOD',
  'BIRD',
  'BIRDBATH',
  'BIRDWATCHER',
  'BIRON',
  'BISCUIT',
  'BISHOP',
  'BITTERSWEET',
  'BLACKAXE',
  'BLACKBELT',
  'BLACKBOOK',
  'BLACKFOOT',
  'BLACKHAWK',
  'BLACKHEART',
  'BLACKMAGIC',
  'BLACKNIGHT',
  'BLACKPEARL',
  'BLACKWATCH',
  'BLADERUNNER',
  'BLANCH',
  'BLARNEY',
  'BLEAKINQUIRY',
  'BLUEANCHOR',
  'BLUESASH',
  'BLUESNORT',
  'BLUESTREAM',
  'BLUEZEPHYR',
  'BLUNT',
  'BOATSWAIN',
  'BOGART',
  'BOLINGBROKE',
  'BONA',
  'BOOKISHMUTE',
  'BOOTY',
  'BORACHIO',
  'BORESIGHT',
  'BORGERKING',
  'BOTH',
  'BOTTOM',
  'BOULT',
  'BOUNDLESSINFORMANT',
  'BOURBON',
  'BOXINGRUMBLE',
  'BOY',
  'BOYET',
  'BOY SCOUT',
  'BRABANTIO',
  'BRANDON',
  'BRAZEN',
  'BRICKTOP',
  'BRIDE',
  'BRIDGEBOUNCE',
  'BROADSIDE',
  'BROKENRECORD',
  'BROOMSTICK',
  'BROTHERS',
  'BRUNEAU',
  'BRUTUS',
  'BUFFALOGREEN',
  'BUGCATCHER',
  'BULLDOG',
  'BULLDOZER',
  'BULLRUN',
  'BULLSEYE',
  'BUSHY',
  'CADENCE',
  'CAESAR',
  'CAITHNESS',
  'CAJABLOSSOM',
  'CAKEBREAD',
  'CALCHAS',
  'CALIBAN',
  'CALIX',
  'CALPURNIA',
  'CALYPSO',
  'CAMBERDADA',
  'CAMELUS',
  'CAMEO',
  'CAMILLO',
  'CANDYGRAM',
  'CANIDIUS',
  'CANOE',
  'CANYON',
  'CANYONDUST',
  'CAPHIS',
  'CAPRICORN',
  'CAPTAIN',
  'CAPTAINCRUNCH',
  'CAPUCIUS',
  'CAPULET',
  'CARAVAN',
  'CARBINE',
  'CARBOY',
  'CARDAMON',
  'CARILLON',
  'CARPAT',
  'CARPENTER',
  'CARRIER',
  'CASCA',
  'CASSANDRA',
  'CASSIEOPIA',
  'CASSIO',
  'CASSIUS',
  'CASTLE',
  'CATALYST',
  'CAYMAN',
  'CELIA',
  'CELLFINDER',
  'CENTERMASS',
  'CENTRICDUD',
  'CENTURION',
  'CERES',
  'CERIMON',
  'CERNET',
  'CHALET',
  'CHALKFUN',
  'CHAMBERLAIN',
  'CHAMPION',
  'CHARACTER',
  'CHARIOT',
  'CHARLES',
  'CHARMIAN',
  'CHASEFALCON',
  'CHATILLON',
  'CHATTERII',
  'CHEEKYMONKEY',
  'CHEER',
  'CHENEY',
  'CHEROKEE',
  'CHESS',
  'CHEWSTICK',
  'CHILDREN',
  'CHIMNEYPOOL',
  'CHIPPEWA',
  'CHIRON',
  'CHORUS',
  'CHUTE',
  'CICERO',
  'CIMBRI',
  'CINEPLEX',
  'CINNA',
  'CITADEL',
  'CITIZEN',
  'CITIZENS',
  'CLARIFYMIND',
  'CLASSIC',
  'CLAUDIO',
  'CLAUDIUS',
  'CLEARSIGHT',
  'CLEOMENES',
  'CLEON',
  'CLEOPATRA',
  'CLERK',
  'CLEVERDEVICE',
  'CLITUS',
  'CLOISTER',
  'CLOTEN',
  'CLOUD',
  'CLOUDRUNNER',
  'CLOUDSHIELD',
  'CLOWN',
  'COASTLINE',
  'COBALTFALCON',
  'COBRA',
  'COBWEB',
  'CODHOOK',
  'COGNOS',
  'COLERIDGE',
  'COMINIUS',
  'COMMONGROUND',
  'COMMONS',
  'COMMONVIEW',
  'CONCERTO',
  'CONFIRM',
  'CONRADE',
  'CONSTANCE',
  'CONTRAOCTAVE',
  'CONTROLAND',
  'CONVEYANCE',
  'COOKIE',
  'COOKIEDOUGH',
  'COPILOT',
  'COPSE',
  'COPTINE',
  'CORALINE',
  'CORALREEF',
  'CORDELIA',
  'CORDOBA',
  'CORIN',
  'CORIOLANUS',
  'CORNELIUS',
  'CORONA',
  'CORRALCLOUD',
  'COSTARD',
  'COUNTESS',
  'COURIERSKILL',
  'COURT',
  'COURTEZAN',
  'COWBOY',
  'CRANKSHAFT',
  'CREAM',
  'CREDIBLE',
  'CRESSIDA',
  'CREST',
  'CRIER',
  'CRISSCROSS',
  'CROMWELL',
  'CROSSBEAM',
  'CROSSEYEDBEAR',
  'CROSSHAIR',
  'CROWN',
  'CROWNROYAL',
  'CRUMPET',
  'CULTWEAVE',
  'CUPID',
  'CURAN',
  'CURIO',
  'CURTIS',
  'CYBERCOP',
  'CYBERTRANS',
  'CYMBELINE',
  'CYR',
  'DAFF',
  'DAMEON',
  'DANCEHALL',
  'DANCER',
  'DANDERSPRITZ',
  'DANGERMOUSE',
  'DARDANIUS',
  'DARDANUS',
  'DARKTHUNDER',
  'DARLING',
  'DASHER',
  'DAUNT',
  'DAVY',
  'DAYSEND',
  'DEADSEA',
  'DECKPIN',
  'DECODEORDAIN',
  'DEEPDIVE',
  'DEEPSKY',
  'DEIPHOBUS',
  'DELLA',
  'DELTA',
  'DEMETRIUS',
  'DEMON',
  'DEMONSPIT',
  'DENIM',
  'DENNIS',
  'DEPUTYDAWG',
  'DERCETAS',
  'DESDEMONA',
  'DESOTO',
  'DESPERADO',
  'DEVIL',
  'DEVILFISH',
  'DEVILHOUND',
  'DEWSWEEPER',
  'DIAMOND',
  'DIANA',
  'DIGGER',
  'DIKTER',
  'DINAR',
  'DIOMEDES',
  'DION',
  'DIONYSUS',
  'DIONYZA',
  'DIRESCALLOP',
  'DIRTSHED',
  'DISCOROUTE',
  'DISHFIRE',
  'DISTANTFOCUS',
  'DISTILLERY',
  'DIVERSITY',
  'DOBIE',
  'DOCTOR',
  'DOGBERRY',
  'DOGCOLLAR',
  'DOGHUT',
  'DOLABELLA',
  'DONALBAIN',
  'DORCAS',
  'DORIAN',
  'DOUBLEARROW',
  'DOUBLETALK',
  'DRAGGABLEKITTEN',
  'DRAGON',
  'DRAGONFLY',
  'DRAWSTRING',
  'DREADNOUGHT',
  'DRTBOX',
  'DRUG',
  'DRUID',
  'DRYAD',
  'DRYTORTUGAS',
  'DUALTIRE',
  'DUKE',
  'DULL',
  'DUMAIN',
  'DUNCAN',
  'DUSKPALLET',
  'DUSTER',
  'DYNAMO',
  'DYNO',
  'EAGLE',
  'EAGLEVIEW',
  'EARPOP',
  'ECHELON',
  'ECHIDNA',
  'ECHO',
  'ECHOBASE',
  'ECLIPSE',
  'EDEN',
  'EDGAR',
  'EDMUND',
  'EGEUS',
  'EGLAMOUR',
  'EGYPTIAN',
  'EIDER',
  'EINSTEIN',
  'ELBOW',
  'ELEANOR',
  'ELEGANTCHAOS',
  'EMERALD',
  'EMILIA',
  'ENDUE',
  'ENIGMA',
  'ENTOURAGE',
  'EPICFAIL',
  'EPICSHELTER',
  'EROS',
  'ESCALUS',
  'ESCANES',
  'ESSEX',
  'ETCHINGSPIN',
  'EUPHRONIUS',
  'EVENINGEASEL',
  'EVILOLIVE',
  'EXPLETIVEDELETED',
  'EXUBERANTCORPSE',
  'EYE',
  'EYES',
  'EYESPY',
  'FABIAN',
  'FACELIFT',
  'FACTOR',
  'FAIRVIEW',
  'FAIRVIEWCOTS',
  'FAIRY',
  'FALCON',
  'FALLENORACLE',
  'FALLOUT',
  'FALSTAFF',
  'FANG',
  'FARLEY',
  'FARMER',
  'FARNDALE',
  'FASCIA',
  'FASCINATOR',
  'FASTBAT',
  'FASTFOLLOWER',
  'FATHER',
  'FAULCONBRIDGE',
  'FENESTRA',
  'FENTON',
  'FERDINAND',
  'FESTE',
  'FIDDLER',
  'FIFTYEXCLAIM',
  'FIREBIRD',
  'FIREFLY',
  'FIRETRUCK',
  'FIRSTFRUITS',
  'FISHBOWL',
  'FISHWAY',
  'FLAMINGO',
  'FLAMINIUS',
  'FLARE',
  'FLATLIQUID',
  'FLAVIUS',
  'FLEANCE',
  'FLEMING',
  'FLINTLOCK',
  'FLORIZEL',
  'FLOWBEE',
  'FLOWERGARDEN',
  'FLUELLEN',
  'FLUTE',
  'FLYLEAF',
  'FOOL',
  'FORD',
  'FOREJUDGE',
  'FOREMAN',
  'FORESTER',
  'FORTINBRAS',
  'FOURSCORE',
  'FOX',
  'FOXAMI',
  'FOXTAIL',
  'FOXTRAIL',
  'FRANCIS',
  'FRANCISCA',
  'FRANCISCO',
  'FREDERICK',
  'FREEWAY',
  'FRENCHMAN',
  'FRESNELEFFECT',
  'FRIAR',
  'FRIARTUCK',
  'FRONTO',
  'FROSTBITE',
  'FROSTBURG',
  'FROSTING',
  'FROTH',
  'GADSHILL',
  'GALACTICHALO',
  'GALAXY',
  'GALLOWAY',
  'GALLUS',
  'GAMBIT',
  'GAMIRA',
  'GAMUT',
  'GANDER',
  'GAOLER',
  'GARDENER',
  'GARDINER',
  'GARLICK',
  'GARTER',
  'GATEKEEP',
  'GATEKEEPER',
  'GAVEL',
  'GEMINI',
  'GENERAL',
  'GENESIS',
  'GENIE',
  'GENTLEMAN',
  'GENTLEMEN',
  'GENTLEWOMAN',
  'GERTRUDE',
  'GEYSER',
  'GHOST',
  'GHOSTWOLF',
  'GIANT',
  'GINPENNANT',
  'GIRL',
  'GIRL SCOUT',
  'GJALLER',
  'GLAIVE',
  'GLENDOWER',
  'GLINT',
  'GLOBALBROKER',
  'GLOBALREACH',
  'GLOBALVISION',
  'GLOBALWATCH',
  'GLOMAR',
  'GMLEARN',
  'GODLIKELESION',
  'GODSURGE',
  'GOLDBERG',
  'GOLDENCARRIAGE',
  'GOLDENRETRIEVER',
  'GOLDMINER',
  'GOLLUM',
  'GONERIL',
  'GONZALO',
  'GOPHERRAGE',
  'GOSSAMER',
  'GOTHAM',
  'GOURMETTROUGH',
  'GOUT',
  'GOVPORT',
  'GOWER',
  'GRAB',
  'GRANDMA',
  'GRANDMASTER',
  'GRANDPA',
  'GRANDPRE',
  'GRAPEVINE',
  'GRATIANO',
  'GREEN',
  'GREENGARDEN',
  'GREGORY',
  'GREMIO',
  'GRIFFITH',
  'GRIMPLATE',
  'GROOM',
  'GROUPDIVE',
  'GRUMIO',
  'GUARD',
  'GUARDIAN',
  'GUIDERIUS',
  'GUILDENSTERN',
  'GUNMAN',
  'GUPY',
  'HABERDASHER',
  'HAIRBALL',
  'HALO',
  'HAMLET',
  'HAMMER',
  'HAMMOCK',
  'HANDLESS',
  'HAPPYFOOT',
  'HARCOURT',
  'HARMONY',
  'HARPIE',
  'HARVAN',
  'HARVEST',
  'HAVASU',
  'HAWK',
  'HAWKEYE',
  'HAYMAKER',
  'HECATE',
  'HECTOR',
  'HELEN',
  'HELENA',
  'HELENUS',
  'HELICANUS',
  'HEMISPHERE',
  'HEMLOCK',
  'HERALD',
  'HERCULES',
  'HERESYITCH',
  'HERETIC',
  'HERMIA',
  'HERMIONE',
  'HERMOS',
  'HERO',
  'HERON',
  'HEXAGON',
  'HIGHCASTLE',
  'HIGHDECIBEL',
  'HIGHLANDS',
  'HIGHTIDE',
  'HIPPOLYTA',
  'HOBGOBLIN',
  'HOLLOWPOINT',
  'HOLOFERNES',
  'HOMEBASE',
  'HOMEMAKER',
  'HORATIO',
  'HORTENSIO',
  'HORTENSIUS',
  'HOST',
  'HOSTESS',
  'HOTZONE',
  'HOUND',
  'HUNTER',
  'HUNTSMAN',
  'HURRICANE',
  'HYMEN',
  'HYPERWIDE',
  'HYSON',
  'HYSSOP',
  'IACHIMO',
  'IAGO',
  'ICEBERG',
  'ICREACH',
  'IMOGEN',
  'INCENSER',
  'INDEX',
  'INDIA',
  'INDRA',
  'INTOLERANT',
  'INTRUDER',
  'IRAS',
  'IRIS',
  'IRISHBEAUTY',
  'ISABELLA',
  'ISHTAR',
  'IVORY',
  'JACKHAMMER',
  'JACKKNIFE',
  'JACKPOT',
  'JADE',
  'JAEGER',
  'JAMY',
  'JAQUENETTA',
  'JEMA',
  'JESSICA',
  'JEWELLER',
  'JOSEPH',
  'JOURNEYMAN',
  'JUBILEECORONA',
  'JUGGLER',
  'JULIA',
  'JULIET',
  'JUMPDOLLAR',
  'JUMPER',
  'JUMPSEAT',
  'JUNE',
  'JUNO',
  'JUPITER',
  'JUSTICE',
  'KAMPUS',
  'KARUS',
  'KATEEL',
  'KATHARINE',
  'KATHERINA',
  'KEA',
  'KEELSON',
  'KEEPER',
  'KESSELRUN',
  'KEYCARD',
  'KEYRUT',
  'KEYSTONE',
  'KILOMISER',
  'KILTING',
  'KIMBO',
  'KING',
  'KIRKBOMB',
  'KITTYBINGE',
  'KLONDIKE',
  'KNIGHT',
  'KNIGHTHAWK',
  'KNIGHTS',
  'KOBAYASHIMARU',
  'KODA',
  'KRAUSS',
  'KRONE',
  'LACEBARK',
  'LADY',
  'LADYLOVE',
  'LAERTES',
  'LAFEU',
  'LAMPSHADE',
  'LANCER',
  'LANYARD',
  'LARUM',
  'LAUNCE',
  'LAUNDROMAT',
  'LAVINIA',
  'LAWYER',
  'LAYERCAKE',
  'LEAKYFAUCET',
  'LEAR',
  'LEGALREPTILE',
  'LEGATE',
  'LEMONWOOD',
  'LENNOX',
  'LEONARDO',
  'LEONATO',
  'LEONINE',
  'LEONTES',
  'LEPIDUS',
  'LEWIS',
  'LEXHOUND',
  'LIBERTY',
  'LIEUTENANT',
  'LIGARIUS',
  'LIGHTFOOT',
  'LIGHTNING',
  'LIGHTNINGTHIEF',
  'LILDIPPER',
  'LIONHEART',
  'LIONROAR',
  'LIONSHARE',
  'LITHIUM',
  'LIZARD',
  'LOCATOR',
  'LODESTAR',
  'LODESTONE',
  'LODOVICO',
  'LOGGERHEAD',
  'LOLLYGAG',
  'LOMA',
  'LONGAVILLE',
  'LONGFELLOW',
  'LOPERS',
  'LORD',
  'LORDS',
  'LORENZO',
  'LOTUS',
  'LUCE',
  'LUCENTIO',
  'LUCETTA',
  'LUCIANA',
  'LUCILIUS',
  'LUCIO',
  'LUCIUS',
  'LUCULLUS',
  'LUMBERYARD',
  'LUNA',
  'LUNCHBOX',
  'LURKER',
  'LUSTRE',
  'LYCANTHROPE',
  'LYCHORIDA',
  'LYMOGES',
  'LYRIC',
  'LYSANDER',
  'LYSIMACHUS',
  'MACBETH',
  'MACDUFF',
  'MACHINESHOP',
  'MACMORRIS',
  'MADCAPOCELOT',
  'MAGIC',
  'MAGNES',
  'MAGNUM',
  'MAGNUMOPUS',
  'MAGOTHY',
  'MAILORDER',
  'MALCOLM',
  'MALLARD',
  'MALVOLIO',
  'MAMBA',
  'MAMILLIUS',
  'MAN',
  'MANASSAS',
  'MANTIS',
  'MAPLE',
  'MARCELLUS',
  'MARDIAN',
  'MARGARELON',
  'MARGARET',
  'MARIA',
  'MARIANA',
  'MARINA',
  'MARINER',
  'MARINERS',
  'MARKHAM',
  'MARSHAL',
  'MARTES',
  'MARTIUS',
  'MARULLUS',
  'MASTER',
  'MASTER-GUNNER',
  'MASTERLINK',
  'MASTERSHAKE',
  'MATRIX',
  'MATTERHORN',
  'MAXFLI',
  'MAYTAG',
  'MECAENAS',
  'MEDLEY',
  'MELUN',
  'MELVIN',
  'MENAS',
  'MENECRATES',
  'MENELAUS',
  'MENTEITH',
  'MENTOR',
  'MERCADE',
  'MERCED',
  'MERCHANT',
  'MERCURY',
  'MERCUTIO',
  'MERLIN',
  'MESA',
  'MESSALA',
  'MESSENGER',
  'MESSIAH',
  'METAWAVE',
  'METRICS',
  'METROTUBE',
  'METTLESOME',
  'MICHAEL',
  'MIDAS',
  'MIDFIELD',
  'MIDNIGHTRIDER',
  'MILKBONE',
  'MINARET',
  'MIRANDA',
  'MIRROR',
  'MISTRALWIND',
  'MJOLNIR',
  'MONKEYROCKET',
  'MONSTERMIND',
  'MONTAGUE',
  'MONTANO',
  'MONTJOY',
  'MOONPENNY',
  'MOONSCAPE',
  'MOPSA',
  'MORAY',
  'MORPHEON',
  'MORPHEUS',
  'MORTIMER',
  'MORTON',
  'MOTH',
  'MOTHER',
  'MOTHMONSTER',
  'MOUNTAIN',
  'MOUSETRAP',
  'MOVEONYX',
  'MULBERRY',
  'MUSKET',
  'MUSKETEER',
  'MUSTANG',
  'MUSTARDSEED',
  'MUTIUS',
  'MYRMIDONS',
  'MYSTIC',
  'NASHUA',
  'NATHANIEL',
  'NATIVEFLORA',
  'NAVAJO',
  'NAVARRO',
  'NEBULA',
  'NECTAR',
  'NELEUS',
  'NEMESIS',
  'NEPTUNE',
  'NEPTUNETHUNDER',
  'NERISSA',
  'NESTOR',
  'NETBOTZ',
  'NETFLOW',
  'NETWORKPUMP',
  'NEWSDEALER',
  'NEXUS',
  'NIAGARAFILES',
  'NICHOLAS',
  'NICKELBACK',
  'NIGHTGLOW',
  'NIGHTHAWK',
  'NIGHTWATCH',
  'NINJA',
  'NINJANIC',
  'NITESURF',
  'NITRO',
  'NOBLEMAN',
  'NOCON',
  'NOMAD',
  'NORMALRUN',
  'NOVELETTE',
  'NUCLEARWINTER',
  'NUCLEON',
  'NURSE',
  'NUTHATCH',
  'NYM',
  'NYMROD',
  'OAK',
  'OAKSTAR',
  'OBELISK',
  'OBERON',
  'OCEAN',
  'OCEANARIUM',
  'OCEANFRONT',
  'OCEANSURF',
  'OCELOT',
  'OCTAVE',
  'OCTAVIA',
  'OCTAVIUS',
  'OCTSKYWARD',
  'OFFICER',
  'OILSTOCK',
  'OILYRAG',
  'OLIVER',
  'OLIVIA',
  'OLYMPIA',
  'OLYMPUS',
  'OMEGA',
  'OMNIGAT',
  'ONEROOF',
  'ONYX',
  'OPHELIA',
  'ORANGEBLOSSOM',
  'ORANGECRUSH',
  'ORION',
  'ORLANDO',
  'ORLANDOCARD',
  'ORSINO',
  'OSAGE',
  'OSCAR',
  'OSRIC',
  'OSTLER',
  'OSWALD',
  'OSWAYO',
  'OTHELLO',
  'OUTLAWS',
  'OUTPARKS',
  'OVERHILL',
  'OWL',
  'OXCART',
  'OZONE',
  'PACKER',
  'PACKETSCOPE',
  'PACKETSWING',
  'PADSTONE',
  'PAGE',
  'PAINTBALL',
  'PAINTEDEAGLE',
  'PAINTER',
  'PALANTERRA',
  'PALMCARTE',
  'PANDAR',
  'PANDARUS',
  'PANOPLY',
  'PANOPTICON',
  'PANTHER',
  'PANTHINO',
  'PARCAE',
  'PARIS',
  'PAROLLES',
  'PARTSHOP',
  'PARTSTREAMER',
  'PASSENGER',
  'PATHFINDER',
  'PATHWAY',
  'PATIENCE',
  'PATRICIAN',
  'PATRIOT',
  'PATROCLUS',
  'PATTERNTRACER',
  'PAULINA',
  'PAWLEYS',
  'PAWN',
  'PEARL',
  'PEASEBLOSSOM',
  'PEBBLE',
  'PEDANT',
  'PEMBROKE',
  'PENCUP',
  'PENDLETON',
  'PEPPERBOX',
  'PERDIDO',
  'PERDITA',
  'PERFECTMOON',
  'PERFECTSTORM',
  'PERICLES',
  'PERMANENTPRESS',
  'PETER',
  'PETO',
  'PETRUCHIO',
  'PHANTOM',
  'PHANTOMNOVA',
  'PHEBE',
  'PHILARIO',
  'PHILEMON',
  'PHILIP',
  'PHILO',
  'PHILOSTRATE',
  'PHILOTUS',
  'PHOENIX',
  'PHOSPHORESSENCE',
  'PHOTOANGLO',
  'PHOTONTORPEDO',
  'PHRYNIA',
  'PICASSO',
  'PIEDMONT',
  'PILOTHOUSE',
  'PINCH',
  'PINDARUS',
  'PINECONE',
  'PINUP',
  'PINWALE',
  'PIRATE',
  'PISANIO',
  'PISCES',
  'PISTOL',
  'PITCHFORD',
  'PIVOT',
  'PIXIE',
  'PLANK',
  'PLANTATION',
  'PLATFORM',
  'PLAYER',
  'PLAYERS',
  'PLUCKHAGEN',
  'PLUS',
  'POCOMOKE',
  'POET',
  'POGODA',
  'POISONIVY',
  'POLARBREEZE',
  'POLIXENES',
  'POLONIUS',
  'POMPEY',
  'POORWILL',
  'POPEYESEAR',
  'POPILIUS',
  'POPPY',
  'POPQUIZ',
  'POPROCKS',
  'POPTOP',
  'PORTER',
  'PORTIA',
  'POST',
  'POUNDSAND',
  'POWDER',
  'POWELL',
  'PREFACE',
  'PREFER',
  'PRESSUREPORT',
  'PRESSUREWAVE',
  'PRIAM',
  'PRIEST',
  'PRIMECANE',
  'PRINCE',
  'PRINCES',
  'PRISM',
  'PROCULEIUS',
  'PRODIGY',
  'PROFORMA',
  'PROPHET',
  'PROSPERO',
  'PROTEIN',
  'PROTEUS',
  'PROTON',
  'PROTOSS',
  'PROVOST',
  'PUBLIUS',
  'PUCK',
  'PULSE',
  'PURPLE',
  'PURSUIVANT',
  'PUTTY',
  'PUZZLECUBE',
  'PYLON',
  'QLIX',
  'QUADRANT',
  'QUANTUM',
  'QUARTERPOUNDER',
  'QUASAR',
  'QUEEN',
  'QUEENSLAND',
  'QUICKPOINT',
  'QUIDDITCH',
  'QUILL',
  'QUINCE',
  'QUINTUS',
  'RADIANT',
  'RADIOSPRING',
  'RADIUS',
  'RADON',
  'RAILHEAD',
  'RAINBOW',
  'RAINFALL',
  'RAISIN',
  'RAMBURES',
  'RAMROD',
  'RANCIDRINSE',
  'RANGER',
  'RATTAN',
  'RAVEN',
  'REACTOR',
  'REBA',
  'REBATE',
  'RECOVERY',
  'REDHAWK',
  'REDRACE',
  'REDRIDGE',
  'REDROOF',
  'REGAL',
  'REGAN',
  'REIGNIER',
  'REMATION',
  'REMEDY',
  'RENOIR',
  'REPTILE',
  'REQUETTE',
  'RESERVEVISION',
  'RESOLUTETITAN',
  'RETRO',
  'RETROSPECTIVE',
  'RETURNSPRING',
  'REYNALDO',
  'RHINEHART',
  'RHYOLITE',
  'RIBBON',
  'RICHTER',
  'RIDDLER',
  'RIMROCK',
  'RINGBILL',
  'RIPCORD',
  'ROADBED',
  'ROADRUNNER',
  'ROBIN',
  'ROCKGARDEN',
  'ROCKSALT',
  'ROCKYKNOB',
  'RODEHOUSE',
  'RODERIGO',
  'ROGUE',
  'ROLLERCOASTER',
  'ROMAN',
  'ROMEO',
  'RONIN',
  'RORIPA',
  'ROSALIND',
  'ROSALINE',
  'ROSE',
  'ROSEBUD',
  'ROSENCRANTZ',
  'ROSS',
  'ROSTER',
  'ROUTEMASTER',
  'ROUTEVIEWS',
  'ROYALNET',
  'RUFF',
  'RUFFER',
  'RUGBY',
  'RUMBUCKET',
  'RUMOUR',
  'RUNWAY',
  'RUSTICBAGGAGE',
  'RUTLEY',
  'RYE',
  'SABERTOOTH',
  'SABRE',
  'SAILOR',
  'SALANIO',
  'SALARINO',
  'SALEM',
  'SALERIO',
  'SALESMAN',
  'SALISBURY',
  'SALTYDOGS',
  'SAMOS',
  'SAMPSON',
  'SANDGOAT',
  'SANDKEY',
  'SANDSTORM',
  'SAPPY',
  'SARACEN',
  'SARATOGA',
  'SARDINE',
  'SATURN',
  'SATURNINUS',
  'SAVILLE',
  'SAVIN',
  'SCALAWAG',
  'SCALLION',
  'SCAMPI',
  'SCAPEL',
  'SCARAB',
  'SCARUS',
  'SCHOOLMONTANA',
  'SCIMITAR',
  'SCISSORS',
  'SCORCHERSIX',
  'SCORECARD',
  'SCORPIOFORE',
  'SCOUT',
  'SCQAWK',
  'SCRIBE',
  'SCRIVENER',
  'SEABOOT',
  'SEADIVER',
  'SEAGULL',
  'SEALION',
  'SEARCHLIGHT',
  'SEARCHLITE',
  'SEASIDEFERRY',
  'SEBASTIAN',
  'SECUREINSIGHT',
  'SEDENA',
  'SEEDSPHERE',
  'SEEKER',
  'SELEUCUS',
  'SEMESTER',
  'SEMITONE',
  'SEMPRONIUS',
  'SENATOR',
  'SENTINEL',
  'SENTINELS',
  'SERENADE',
  'SERGEANT',
  'SERPENT',
  'SERRATEDEDGE',
  'SERUM',
  'SERVANT',
  'SERVANTS',
  'SERVILIUS',
  'SERVING-MEN',
  'SETTEE',
  'SEXTON',
  'SEYTON',
  'SHADOW',
  'SHADOWCAT',
  'SHAMROCK',
  'SHAREDQUEST',
  'SHARK',
  'SHARKFIN',
  'SHARKFINN',
  'SHARKFURY',
  'SHELLOCK',
  'SHELLTRUMPET',
  'SHENANIGANS',
  'SHEPHERD',
  'SHERIFF',
  'SHERMAN',
  'SHERPA',
  'SHIFTINGSHADOW',
  'SHILLELAGH',
  'SHORTHAND',
  'SHOTGIANT',
  'SHYLOCK',
  'SIDELIGHT',
  'SIERRAMIST',
  'SIGABA',
  'SIGCOM',
  'SIGSALY',
  'SILENCE',
  'SILIUS',
  'SILKWORTH',
  'SILLYBUNNY',
  'SILO',
  'SILVER',
  'SILVERCOMET',
  'SILVIA',
  'SILVIUS',
  'SIMONIDES',
  'SIMPCOX',
  'SIMPLE',
  'SIRE',
  'SIWARD',
  'SKIDROWE',
  'SKIPJACK',
  'SKOPE',
  'SKYNET',
  'SKYSCRAPER',
  'SKYWRITER',
  'SLENDER',
  'SLINGSHOT',
  'SLIPSTICK',
  'SLIVER',
  'SMARTTRACKER',
  'SNARE',
  'SNICK',
  'SNOUT',
  'SNOWHAZE',
  'SNUG',
  'SOAPOPERA',
  'SOCIALSTAMP',
  'SOCIOPATH',
  'SODAPRESSED',
  'SOLDIER',
  'SOLDIERS',
  'SOLINUS',
  'SOLO',
  'SOMALGET',
  'SON',
  'SOOTHSAYER',
  'SORTINGHAT',
  'SORTINGLEAD',
  'SOUNDER',
  'SOUTHWINDS',
  'SPARKLEPONY',
  'SPEARGUN',
  'SPECTATOR',
  'SPECTRE',
  'SPECULATION',
  'SPEED',
  'SPEEDBUMP',
  'SPHINX',
  'SPINALTAP',
  'SPIRIT',
  'SPIRITFIRE',
  'SPIT',
  'SPITEFULANGEL',
  'SPLITGLASS',
  'SPLUNK',
  'SPOKE',
  'SPORTCOAST',
  'SPOTBEAM',
  'SPRIG',
  'SPRINGRAY',
  'SPRINKLER',
  'SPYDER',
  'SQUAWK',
  'STAKECLAIM',
  'STALKER',
  'STAR',
  'STARBURST',
  'STARDUST',
  'STARFIRE',
  'STARLIGHT',
  'STARPROC',
  'STARSEARCH',
  'STARVELING',
  'STATEROOM',
  'STEALTHFIGTHER',
  'STEELFLAUTA',
  'STEELKNIGHT',
  'STEELWINTER',
  'STEEPLEBUSH',
  'STELLAR',
  'STEPHANIE',
  'STEPHANO',
  'STEWARD',
  'STINGER',
  'STINGRAY',
  'STONEGHOST',
  'STONEHOUSE',
  'STORMBREW',
  'STORMFORCE',
  'STOUTHEART',
  'STRATO',
  'STRATOS',
  'STRAWHAT',
  'STRAWHORSE',
  'STREAMLINER',
  'STRETCH',
  'STROMTIME',
  'STRONGMITE',
  'STRUM',
  'STYLISHCHAMP',
  'SUBSTRATUM',
  'SUEDE',
  'SULPHUR',
  'SUN',
  'SUNBURN',
  'SUNSCREEN',
  'SUNSHINE',
  'SUPERNOVA',
  'SURFBOARD',
  'SURGEON',
  'SURREY',
  'SURVEYOR',
  'SWEEPFORWARD',
  'SWORDFISH',
  'SWORDSMAN',
  'SYNAPSE',
  'TABLON',
  'TACOSUAVE',
  'TAILOR',
  'TALIS',
  'TALISMAN',
  'TAMORA',
  'TAPERLAY',
  'TARMAC',
  'TAROTCARD',
  'TATTERAN',
  'TATTOO',
  'TAURUS',
  'TAWDRYYARD',
  'TEABALL',
  'TELLURIAN',
  'TEMPEST',
  'TEMPLER',
  'TENNIS',
  'TETLEY',
  'THAISA',
  'THALIARD',
  'THAWFACTOR',
  'THEORYMASTER',
  'THERAPYCHEATER',
  'THERSITES',
  'THESEUS',
  'THESPIS',
  'THIEVES',
  'THINTREAD',
  'THORNYHOSTILE',
  'THUMB',
  'THUNDER',
  'THUNDERCLOUD',
  'THUNDERISLAND',
  'THURIO',
  'THYREUS',
  'TIAMAT',
  'TICKETWINDOW',
  'TIDALSURGE',
  'TIDE',
  'TIDEWAY',
  'TIGER',
  'TIKICUBE',
  'TIMANDRA',
  'TIMBERLINE',
  'TIME',
  'TIMON',
  'TINKERBELL',
  'TINMAN',
  'TINSEL',
  'TINTINIUS',
  'TITAN',
  'TITANIA',
  'TITLEHOLDER',
  'TITUS',
  'TOPAZ',
  'TOPIARYGARDEN',
  'TOPROCK',
  'TORUS',
  'TOUCHSTONE',
  'TOUCHWOLE',
  'TOWER',
  'TOWERPOWER',
  'TOWNSMAN',
  'TOXICARE',
  'TOYGRIPPE',
  'TRACFIN',
  'TRACTOR',
  'TRADER',
  'TRAILBLAZER',
  'TRAILMAPPER',
  'TRANIO',
  'TRANSIENT',
  'TRANSPORTORO',
  'TRANSX',
  'TRAVELER',
  'TRAVELLERS',
  'TRAVERS',
  'TREASURETROVE',
  'TREBLECLEF',
  'TREBONIUS',
  'TRIBUNES',
  'TRIBUTARY',
  'TRIGGERFISH',
  'TRINCULO',
  'TRINE',
  'TRIREME',
  'TRITON',
  'TROILUS',
  'TROPICPUMA',
  'TROPICTHUNDER',
  'TRUMPET',
  'TSUNAMI',
  'TUBAL',
  'TUBE',
  'TUMULT',
  'TUNDRA',
  'TUNDRAFREEZE',
  'TUNER',
  'TUNINGFORK',
  'TURBINE',
  'TURMEROL',
  'TURNSTILE',
  'TURNSTYLE',
  'TURNWEALTHY',
  'TURTLEPOWER',
  'TUSKATTIRE',
  'TUTELAGE',
  'TWEED',
  'TWISTEDPATH',
  'TYBALT',
  'TYPHON',
  'UDEAUS',
  'ULTRA',
  'ULYSSES',
  'UMBRA',
  'UNICORN',
  'UNICORNSANDWICH',
  'UNIFORM',
  'UNITY',
  'UPWARD',
  'URSULA',
  'USHER',
  'VALENTINE',
  'VALERIA',
  'VANGUARD',
  'VARRIUS',
  'VARRO',
  'VAUX',
  'VENATOR',
  'VENONA',
  'VENTIDIUS',
  'VENUS',
  'VENUSAFFECT',
  'VERGES',
  'VERNON',
  'VESUVIUS',
  'VICTORYDANCE',
  'VICTORYUNIFORM',
  'VIEWPLATE',
  'VIGILANT',
  'VINCENTIO',
  'VINEYARD',
  'VINSON',
  'VINTAGE',
  'VINTNER',
  'VIOLA',
  'VIRGILIA',
  'VIVIDDREAM',
  'VIXEN',
  'VOICESAIL',
  'VOLCANO',
  'VOLSCE',
  'VOLTEMAND',
  'VOLUMNIA',
  'VOLUMNIUS',
  'VOLUNTEER',
  'VORTEX',
  'VOTEDOOR',
  'VOXGLO',
  'VOYEUR',
  'WABASH',
  'WAGONBED',
  'WALBURN',
  'WALKER',
  'WARRIOR',
  'WASP',
  'WATCH',
  'WATCHDOG',
  'WATCHMAN',
  'WATERFRONT',
  'WATERWITCH',
  'WAVELEGAL',
  'WAYLAND',
  'WEASEL',
  'WEBCANDID',
  'WELLGROUNDED',
  'WELLSPRING',
  'WESTERNSTAR',
  'WESTPORT',
  'WHARPDRIVE',
  'WHIRLWIND',
  'WHISPER',
  'WHITEBIRCH',
  'WHITEBOX',
  'WHITELIST',
  'WHITESQUALL',
  'WHITETAMALE',
  'WHIZBANG',
  'WIDOW',
  'WILLIAM',
  'WILLIAMS',
  'WILLOW',
  'WILLY',
  'WINCHESTER',
  'WINDCHASER',
  'WINDIGO',
  'WINDSORBLUE',
  'WINDSORGREEN',
  'WING',
  'WINGS',
  'WINTERGARDEN',
  'WIRESHARK',
  'WISPYKNIT',
  'WISTFULTOLL',
  'WITCH',
  'WITCHHUNT',
  'WITNESS',
  'WIZARD',
  'WOLF',
  'WOLFPOINT',
  'WOODVILE',
  'WORDGOPHER',
  'WRANGLER',
  'XBIT',
  'XCONCORD',
  'YACHTSHOP',
  'YANKEE',
  'YELLOWSTONE',
  'YIELD',
  'YOKE',
  'YUKON',
  'ZAP',
  'ZARF',
  'ZEUS',
  'ZION',
  'ZODIACARRAY',
  'ZOMBIEARMY',
  'ZOOM',
]

export default NAMES
